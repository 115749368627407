import { data } from 'jquery'
import CLRequest from '../request'


// 统一模板
export function selectPublishDataById(id) {
  return CLRequest.get({ 
    url: "/api/common/selectPublishDataById/" + id,
  })
}


// 统一模板
export function partMain(id,status=null) {
  if(status){
    return CLRequest.get({ 
      url: "/api/common/getDetails/" + id + '/' + status,
    })
  }else{
    return CLRequest.get({ 
      url: "/api/common/getDetails/" + id ,
    })
  }
}

// banner
export function getBanner(params) {
  return CLRequest.get({ 
    url: "/api/banner/allList",
    params
  })
}
// banner
export function getIndexBanner(status) {
  return CLRequest.get({ 
    url: "/api/bannerList/allList/" + status,
    
  })
}
// 产品下拉菜单
export function getPrograma(typeId) {
  return CLRequest.get({ 
    url: "/api/programa/buildProgramaTree/" + typeId,
    
  })
}

// 产品下拉菜单
export function getBaseInfo() {
  return CLRequest.get({ 
    url: "/api/home/getDetails",
  })
}


// 投资者关系列表
export function getInvestList(params) {
  return CLRequest.get({ 
    url: "/api/notice/allList",
    params
  })
}

// 搜索
export function searchAll(params) {
  return CLRequest.get({ 
    url: "/api/keyWord",
    params
    
  })
}

// 全局新闻搜索
export function getSearchNewsList(params) {
  return CLRequest.get({ 
    url: "/api/news/newsListByTitle",
    params
  })
}

// 查询字典类型
export function getDictType(data) {
  return CLRequest.post({
    url: '/api/dict/type/list',
    data
  })
}

// 查询字典数据
export function getDictData(data) {
  return CLRequest.post({
    url: '/api/dict/data/list',
    data
  })
}

// 多语言查询字典数据
export function listLanguage(data) {
  return CLRequest.post({
    url: '/api/language/list',
    data
  })
}
