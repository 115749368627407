import { createRouter, createWebHistory } from 'vue-router'
import sessionCache from '@/utils/cache'

const langs = ['zh', 'en', 'jp', 'th', 'ko', 'es', 'de', 'fr', 'ru']
const langReg = langs.join('|')

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
const routes = [
  {
    path: '/',
    redirect:'/index'
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('../views/index/index.vue'),
    meta: {
      title: '首页',
    },
  },
  {
    path: '/:lang(' + langReg + ')/index',
    name: 'home',
    component: () => import('../views/index/index.vue'),
    meta: {
      title: '首页',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/investment',
    name: 'investment',
    component: () => import('../views/index/investment.vue'),
    meta: {
      title: '投资者关系',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/ESG',
    name: 'ESG',
    component: () => import('../views/index/ESG.vue'),
    meta: {
      title: 'ESG',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/search',
    name: 'search',
    component: () => import('../views/index/search.vue'),
    meta: {
      title: '搜索',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/login',
    name: 'login',
    component: () => import('../views/login/login.vue'),
    meta: {
      title: '登录',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/register',
    name: 'register',
    component: () => import('../views/login/register.vue'),
    meta: {
      title: '注册',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/wechatLogin',
    name: 'wechatLogin',
    component: () => import('../views/login/wechatLogin.vue'),
    meta: {
      title: '微信登录',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/deregister',
    name: 'deregister',
    component: () => import('../views/login/deregister.vue'),
    meta: {
      title: '注销',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/productLists',
    name: 'productLists',
    component: () => import('../views/product/product_lists.vue'),
    meta: {
      title: '产品中心',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/productList/:id',
    name: 'productList',
    component: () => import('../views/product/product_list.vue'),
    meta: {
      title: '产品中心',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/productDetails/:id',
    name: 'productDetails',
    component: () => import('../views/product/product_details.vue'),
    meta: {
      title: '产品详情',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/productComparison',
    name: 'productComparison',
    component: () => import('../views/product/productComparison.vue'),
    meta: {
      title: '产品对比工具',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/case',
    name: 'case',
    component: () => import( '../views/solution/case.vue'),
    meta: {
      title: '案例中心',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/caseDetail/:id',
    name: 'caseDetail',
    component: () => import( '../views/solution/caseDetail.vue'),
    meta: {
      title: '案例详情',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/application/:id',
    name: 'application',
    component: () => import( '../views/solution/application.vue'),
    meta: {
      title: '解决方案',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/industry/:id',
    name: 'industry',
    component: () => import( '../views/solution/industry.vue'),
    meta: {
      title: '解决方案',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/productLevel/:id',
    name: 'productLevel',
    component: () => import( '../views/solution/productLevel.vue'),
    meta: {
      title: '解决方案',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/service',
    name: 'service',
    component: () => import( '../views/service/service.vue'),
    meta: {
      title: '服务与支持',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/repair',
    name: 'repair',
    component: () => import( '../views/service/repair.vue'),
    meta: {
      title: '自助报修',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/repairList',
    name: 'repairList',
    component: () => import( '../views/service/repairList.vue'),
    meta: {
      title: '报修进度',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/repairDetail/:id',
    name: 'repairDetail',
    component: () => import( '../views/service/repairDetail.vue'),
    meta: {
      title: '报修详情',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/showRoom',
    name: 'showRoom',
    component: () => import( '../views/service/showRoom.vue'),
    meta: {
      title: '虚拟展厅',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/jakaAcademy',
    name: 'jakaAcademy',
    component: () => import( '../views/service/jakaAcademy.vue'),
    meta: {
      title: '节卡学院',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/download',
    name: 'download',
    component: () => import( '../views/service/download.vue'),
    meta: {
      title: '资料中心',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/commonQuestion',
    name: 'commonQuestion',
    component: () => import( '../views/service/commonQuestion.vue'),
    meta: {
      title: '常见问题',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/provider',
    name: 'provider',
    component: () => import( '../views/service/provider.vue'),
    meta: {
      title: '合作伙伴',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/virtual',
    name: 'virtual',
    component: () => import( '../views/service/virtual.vue'),
    meta: {
      title: '虚拟体验',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/jaka+',
    name: 'jaka+',
    component: () => import( '../views/jaka/jaka+.vue'),
    meta: {
      title: 'JAKA+',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/jakaList/:id',
    name: 'jakaList',
    component: () => import( '../views/jaka/jakaList.vue'),
    meta: {
      title: 'JAKA+',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/jakaDetail/:id',
    name: 'jakaDetail',
    component: () => import( '../views/jaka/jakaDetail.vue'),
    meta: {
      title: 'JAKA+',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/about',
    name: 'about',
    component: () => import('../views/about/about.vue'),
    meta: {
      title: '关于节卡',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/contact',
    name: 'contact',
    component: () => import( '../views/about/contact.vue'),
    meta: {
      title: '联系我们',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/join',
    name: 'join',
    component: () => import( '../views/about/join.vue'),
    meta: {
      title: '加入节卡',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/position',
    name: 'position',
    component: () => import( '../views/about/position.vue'),
    meta: {
      title: '节卡职位',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/news',
    name: 'news',
    component: () => import( '../views/about/news.vue'),
    meta: {
      title: '新闻中心',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/newsDetail/:id',
    name: 'newsDetail',
    component: () => import( '../views/about/newsDetail.vue'),
    meta: {
      title: '新闻详情',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/flsm',
    name: 'flsm',
    component: () => import( '../views/comm/flsm.vue'),
    meta: {
      title: '注册与隐私保护服务协议',
    },
  },
  // {
  //   path: '/map',
  //   name: 'map',
  //   component: () => import( '../views/comm/map.vue'),
  //   meta: {
  //     title: 'jaka',
  //   },
  // },
  // {
  //   path: '/ystk',
  //   name: 'ystk',
  //   component: () => import( '../views/comm/ystk.vue'),
  //   meta: {
  //     title: 'jaka',
  //   },
  // },
  // 预览 Preview
  {
    path: '/:lang(' + langReg + ')?/preview/index',
    name: '1home',
    component: () => import('../views/index/index_preview.vue'),
    meta: {
      title: '首页',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/preview/investment',
    name: '1investment',
    component: () => import('../views/index/investment_preview.vue'),
    meta: {
      title: '投资者关系',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/preview/ESG',
    name: '1ESG',
    component: () => import('../views/index/ESG_preview.vue'),
    meta: {
      title: 'ESG',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/preview/productLists',
    name: '1productLists',
    component: () => import('../views/product/product_lists_preview.vue'),
    meta: {
      title: '产品中心',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/preview/productList/:id',
    name: '1productListPreview',
    component: () => import('../views/product/product_list_preview.vue'),
    meta: {
      title: '产品中心',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/preview/productDetails/:id',
    name: '1productDetailsPreview',
    component: () => import('../views/product/product_details_preview.vue'),
    meta: {
      title: '产品详情',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/preview/case',
    name: '1case',
    component: () => import( '../views/solution/case.vue'),
    meta: {
      title: '案例中心',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/preview/caseDetail/:id',
    name: '1caseDetailPreview',
    component: () => import( '../views/solution/caseDetail_preview.vue'),
    meta: {
      title: '案例详情',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/preview/application/:id',
    name: '1applicationPreview',
    component: () => import( '../views/solution/application_preview.vue'),
    meta: {
      title: '解决方案',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/preview/industry/:id',
    name: '1industryPreview',
    component: () => import( '../views/solution/industry_preview.vue'),
    meta: {
      title: '解决方案',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/preview/productLevel/:id',
    name: '1productLevelPreview',
    component: () => import( '../views/solution/productLevel_preview.vue'),
    meta: {
      title: '解决方案',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/preview/service',
    name: '1service',
    component: () => import( '../views/service/service_preview.vue'),
    meta: {
      title: '服务与支持',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/preview/jakaAcademy',
    name: '1jakaAcademy',
    component: () => import( '../views/service/jakaAcademy_preview.vue'),
    meta: {
      title: '节卡学院',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/preview/download',
    name: '1download',
    component: () => import( '../views/service/download_preview.vue'),
    meta: {
      title: '资料中心',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/preview/commonQuestion',
    name: '1commonQuestion',
    component: () => import( '../views/service/commonQuestion_preview.vue'),
    meta: {
      title: '常见问题',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/preview/commonQuestionDetail',
    name: 'commonQuestionDetail',
    component: () => import( '../views/service/commonQuestion_preview_detail.vue'),
    meta: {
      title: '常见问题',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/preview/jaka+',
    name: '1jaka+',
    component: () => import( '../views/jaka/jaka_preview.vue'),
    meta: {
      title: 'JAKA+',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/preview/jakaList/:id',
    name: '1jakaList',
    component: () => import( '../views/jaka/jakaList_preview.vue'),
    meta: {
      title: 'JAKA+',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/preview/jakaDetail/:id',
    name: '1jakaDetailPreview',
    component: () => import( '../views/jaka/jakaDetail_preview.vue'),
    meta: {
      title: 'JAKA+',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/preview/about',
    name: '1about',
    component: () => import('../views/about/about_preview.vue'),
    meta: {
      title: '关于节卡',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/preview/contact',
    name: '1contact',
    component: () => import( '../views/about/contact_preview.vue'),
    meta: {
      title: '联系我们',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/preview/join',
    name: '1join',
    component: () => import( '../views/about/join_preview.vue'),
    meta: {
      title: '加入节卡',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/preview/position',
    name: '1position',
    component: () => import( '../views/about/position_preview.vue'),
    meta: {
      title: '节卡职位',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/preview/news',
    name: '1news',
    component: () => import( '../views/about/news_preview.vue'),
    meta: {
      title: '新闻中心',
    },
  },
  {
    path: '/:lang(' + langReg + ')?/preview/newsDetail/:id',
    name: '1newsDetailPreview',
    component: () => import( '../views/about/newsDetail_preview.vue'),
    meta: {
      title: '新闻详情',
    },
  },
  {
    path: '/:lang(' + langReg + ')',
    redirect: {
      name: 'home'
    }
  },
  //404页面捕获
  {
    path: '/NotFound',
    name: 'test',
    component: () => import( '../views/comm/NotFound.vue'),
    meta: {
      title: 'NotFound',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/NotFound'
  },
]
// process.env.VUE_APP_BASE_URL
const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "act",
  // return 期望滚动到哪个的位置

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(savedPosition);
        }, 200);
      });
    } else {
      // vue2.0  x  y  控制
      // vue3.0 left top 控制
      return { top: 0 };
    }
  },
})

NProgress.configure({ showSpinner: false })
router.beforeEach((to, from, next) => {
  NProgress.start()
  if (to.meta.title) {
    document.title = to.meta.title;
    if(to.path == '/login' ||  to.path == '/wechatLogin' ){
      if(sessionCache.getCache('jakaUserInfo')  ){
        if(sessionCache.getCache('jakaWhereFrom')){
          next(sessionCache.getCache('jakaWhereFrom'))
        }else{
          next(from.path)
        }
      }else{
        next()
      }
    }else if(to.path == '/register'){
      if(sessionCache.getCache('jakaUserInfo')  ){
        next(from.path)
      }else{
        next()
      }
    }else if(to.path == '/deregister'){
      if(sessionCache.getCache('jakaUserInfo') ){
        if(!sessionCache.getCache('jakaUserInfo').token){
          next(from.path)
        }else{
          next()
        }
      }else{
        next(from.path)
      }
    }else{
      next()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
export default router
